import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AddAlarm,
  AddBox,
  Assignment,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  Edit,
  GetApp,
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import FilterBar from "components/PageableTable/FilterBar";
import PageableTable from "components/PageableTable/PageableTable";
import { STATUSES } from "config/constants";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format, isPast, subDays } from "date-fns";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listIssues } from "shared/network/issues.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import { useColoredRowStyles } from "./NewIssueOverviewList";
import { getIssueFile, getIssueTigFile } from "shared/network/files.api";
import { dowloadPdf } from "shared/util/fileDownload";
import { useSnackbar } from "notistack";
import { Issue } from "shared/types";
import { faClipboardCheck, faFilePen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  fromIssueGenerate?: boolean;
  isSelected?: (item: any) => boolean;
  addToActiveList?: (item: any) => void;
  removeFromActiveList?: (item: any) => void;
};

const IssueList = ({
  fromIssueGenerate,
  isSelected,
  addToActiveList,
  removeFromActiveList,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const colorClasses = useColoredRowStyles();
  const { setHeaderButtons } = useHeader();
  const isCompanyDetails = location.pathname.includes("/company-details");
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant?.tenant;

  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));

  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const isPartner =
    account.user.relUserCompany?.length !== 0 &&
    !account.user.isSuperAdmin &&
    !selectedRelTenant.isTenantAdmin;

  const [loadingType, setLoadingType] = useState<"WORKSHEET" | "TIG" | null>(null);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-issue-list-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-issue-list-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const issueList = useQuery(
    ["listIssueQuery", page, pageSize, filterValue, sortState, selectedRelTenant.tenant.id],
    async () => {
      let predicates = "";

      if (isPartner) {
        predicates = `company.id=${account?.user?.relUserCompany?.[0]?.company.id};createdBy=${account?.user?.id}`;
      }
      if (isCompanyDetails) {
        predicates = `company.id=${id}`;
      }

      const { data } = await listIssues(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue + predicates,
        sortState,
      );

      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "multiSelect",
      headerName: " ",
      width: 50,
      hide: !fromIssueGenerate,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {isSelected && isSelected(row) ? (
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                removeFromActiveList && removeFromActiveList(row);
              }}
            >
              <CheckBoxOutlined />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                addToActiveList && addToActiveList(row);
              }}
            >
              <CheckBoxOutlineBlankOutlined />
            </IconButton>
          )}
        </Box>
      ),
    },
    {
      field: "serialNumber",
      headerName: t("issues.formValues.id"),
      flex: 3,
    },
    {
      field: "status",
      headerName: t("issues.formValues.status"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t(`common:statuses.${row.status}`),
      type: "select",
    },
    {
      field: "name",
      headerName: t("issues.formValues.subject"),
      flex: 3,
    },
    {
      field: "company.name",
      headerName: t("issues.formValues.company"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) => (row.company ? row.company.name : ""),
    },
    {
      field: "project.name",
      headerName: t("issues.formValues.project"),
      flex: 3,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.project ? row.project.name : t("common:notification.NO_PROJECT"),
    },
    // {
    //   field: "contract.name",
    //   headerName: t("issues.formValues.contract"),
    //   flex: 3,
    //   valueGetter: ({ row }: GridValueGetterParams) =>
    //     row.contract ? row.contract.name : t("common:notification.NO_CONTRACT"),
    // },
    {
      field: "startDate",
      headerName: t("issues.formValues.startDate"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.startDate), "P", {
          locale: supportedLocales[i18n.language],
        }),
      type: "singleDateStart",
    },
    {
      field: "endDate",
      headerName: t("issues.formValues.endDate"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.endDate), "P", {
          locale: supportedLocales[i18n.language],
        }),
      type: "singleDateEnd",
    },
    {
      field: "actions",
      headerName: " ",
      width: 200,
      sortable: false,
      hide: fromIssueGenerate,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => getRowButtons(row),
    },
  ];

  useEffect(() => {
    !fromIssueGenerate &&
      setHeaderButtons(
        <Box display="flex" gridGap={8} flexWrap="wrap">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Button
              variant="outlined"
              component={Link}
              to="/issues/all/time-entry-create"
              startIcon={<AddAlarm />}
            >
              {t("timeEntry.title")}
            </Button>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_ADMIN"]) && (
            <Button component={Link} to="/issues/all/issue-create" startIcon={<AddBox />}>
              {t("issues.createIssue")}
            </Button>
          )}
        </Box>,
      );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (!["actions", "multiSelect"].includes(params.field)) {
      history.push(`/issues/all/issue-details?id=${params.row.id}`);
    }
  }

  const getRowButtons = (row: any) => (
    <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%" gridGap={4}>
      {!!row?.worksheet &&
        hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
          <Tooltip
            title={
              row?.worksheetPath ? t("issues.worksheet.download") : t("issues.worksheet.noDownload")
            }
          >
            <div>
              {loadingType === "WORKSHEET" ? (
                <Box display="flex" justifyContent="center" alignItems="center" width={30}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <IconButton
                  size="small"
                  color="primary"
                  disabled={!row?.worksheetPath}
                  onClick={() => downloadPDF(row, "WORKSHEET")}
                >
                  <FontAwesomeIcon icon={faFilePen} style={{ width: 24, height: 24 }} />
                </IconButton>
              )}
            </div>
          </Tooltip>
        )}
      {!!row?.worksheet &&
        hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
          <Tooltip
            title={
              row?.tigPath ? t("issues.worksheet.tigDownload") : t("issues.worksheet.noTigDownload")
            }
          >
            <div>
              {loadingType === "TIG" ? (
                <Box display="flex" justifyContent="center" alignItems="center" width={30}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <IconButton
                  size="small"
                  color="primary"
                  disabled={!row?.tigPath}
                  onClick={() => downloadPDF(row, "TIG")}
                >
                  <FontAwesomeIcon icon={faClipboardCheck} style={{ width: 24, height: 24 }} />
                </IconButton>
              )}
            </div>
          </Tooltip>
        )}
      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
        <Tooltip title={t("timeEntry.title").toString()}>
          <div>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to={`/issues/all/time-entry-create?id=${row.id}`}
            >
              <AddAlarm />
            </IconButton>
          </div>
        </Tooltip>
      )}
      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
        <Tooltip title={t("issues.issueDetails").toString()}>
          <div>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to={`/issues/all/issue-details?id=${row.id}`}
            >
              <Assignment />
            </IconButton>
          </div>
        </Tooltip>
      )}
      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_ADMIN"]) && (
        <Tooltip title={t("issues.modifyIssue").toString()}>
          <div>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to={`/issues/all/issue-modify?id=${row.id}`}
            >
              <Edit />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </Box>
  );

  const downloadPDF = async (issue: Issue, type: "WORKSHEET" | "TIG") => {
    setLoadingType(type);
    if (issue?.id) {
      try {
        if (type === "TIG") {
          const { data } = await getIssueTigFile(issue.id?.toString(), tenant?.id);
          dowloadPdf(data, issue?.serialNumber?.replace("MUNKALAP", "TIG"));
        } else {
          const { data } = await getIssueFile(issue.id?.toString(), tenant?.id);
          dowloadPdf(data, issue?.serialNumber);
        }
      } catch (error: any) {
        enqueueSnackbar(
          t("common:notification.download.failure", { subject: t(`common:pdfDownload.${type}`) }),
          { variant: "error" },
        );
      }
    }
    setLoadingType(null);
  };

  function getRowClassName(row: any) {
    if (selectedRelTenant?.tenant?.isIssueColoring) {
      if (row?.status === "CLOSED") {
        return colorClasses.green;
      } else if (isPast(new Date(row?.endDate))) {
        return colorClasses.red;
      } else if (isPast(subDays(new Date(row?.endDate), 7))) {
        return colorClasses.orange;
      } else {
        return colorClasses.green;
      }
    } else return "";
  }

  return (
    <>
      {!matchesSm ? (
        <Card style={{ marginBottom: 8 }}>
          <FilterBar
            filterOptions={[
              {
                columnName: "status",
                options: STATUSES.map(value => {
                  return {
                    translated: t(`common:statuses.${value}`),
                    value,
                  };
                }),
              },
            ]}
            columns={columns}
            filterable={[
              "id",
              "status",
              "name",
              "company.name",
              "project.name",
              "startDate",
              "endDate",
              "contract.name",
            ]}
            setTableState={setTableState}
            sessionStorageKey="issue"
            id={selectedRelTenant?.tenant?.id}
          />
          {issueList.data?.page?.content?.map(issue => {
            const buttons = getRowButtons(issue);
            const rowClassName = getRowClassName(issue);

            return (
              <Box key={issue?.id}>
                <Box style={{ padding: 8 }} className={rowClassName}>
                  <Box display="flex">
                    <Box>
                      <a
                        style={{ textDecoration: "unset", color: "unset" }}
                        href={`/issues/all/issue-details?id=${issue.id}`}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {issue.serialNumber} - {issue.name}
                        </Typography>
                      </a>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>
                          {!!issue.company && `${t("issues.formValues.company")}: `}
                        </span>
                        {`${issue.company?.name}`}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>
                          {!!issue.status && `${t("issues.formValues.status")}: `}
                        </span>
                        {`${t(`common:statuses.${issue.status}`)}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" gridGap={4}>
                    {buttons}
                  </Box>
                </Box>
                <Divider />
              </Box>
            );
          })}
          {issueList.data && issueList.data?.page?.totalPages > 1 && (
            <Pagination
              size="small"
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
              count={issueList.data?.page?.totalPages}
              color="primary"
              page={issueList.data?.page?.number + 1}
              onChange={(_, page) => {
                setTableState(state => {
                  return { ...state, page: page - 1 };
                });
              }}
              siblingCount={1}
            />
          )}
        </Card>
      ) : (
        <PageableTable
          filterOptions={[
            {
              columnName: "status",
              options: STATUSES.map(value => {
                return {
                  translated: t(`common:statuses.${value}`),
                  value,
                };
              }),
            },
          ]}
          filterable={[
            "id",
            "status",
            "name",
            "company.name",
            "project.name",
            "startDate",
            "endDate",
            "contract.name",
          ]}
          sortState={sortState}
          setSortState={setSortState}
          sessionStorageKey="issue-list"
          tableState={tableState}
          setTableState={setTableState}
          columns={columns}
          query={issueList}
          onCellClick={onCellClick}
          getRowClassName={params => {
            if (selectedRelTenant?.tenant?.isIssueColoring) {
              if (params.row?.status === "CLOSED") {
                return colorClasses.green;
              } else if (isPast(new Date(params.row?.endDate))) {
                return colorClasses.red;
              } else if (isPast(subDays(new Date(params.row?.endDate), 7))) {
                return colorClasses.orange;
              } else {
                return colorClasses.green;
              }
            } else return "";
          }}
        />
      )}
    </>
  );
};
export default IssueList;
