import {
  faClipboardCheck,
  faFileInvoice,
  faFilePen,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Add,
  AddAlarm,
  AddCircle,
  Assignment,
  BusinessCenter,
  CalendarToday,
  Edit,
  Info,
  Person,
  Title,
} from "@material-ui/icons";
import queryClient from "config/query";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getIssueFile, getIssueTigFile } from "shared/network/files.api";
import { updateIssueStatus } from "shared/network/issues.api";
import { Issue } from "shared/types";
import { dowloadPdf } from "shared/util/fileDownload";

type Props = {
  issue: Issue;
  refetch: () => void;
  setCreateOpen: Dispatch<SetStateAction<boolean>>;
  setModalIssue: Dispatch<SetStateAction<Issue | undefined>>;
  length: number;
};

const KanbanItem = ({ issue, refetch, length, setCreateOpen, setModalIssue }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const startDate = new Date(issue.startDate);
  const endDate = new Date(issue.endDate);

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [_, drag, preview] = useDrag(() => ({
    type: "card",
    item: { issueItem: issue },
    end: async (item, monitor) => {
      const dropResult: {
        issue: Issue;
      } | null = monitor.getDropResult();
      if (item && dropResult) {
        let tempIssue = item.issueItem;
        if (dropResult.issue.status !== tempIssue.status && tempIssue && dropResult.issue.status) {
          changeIssueStatus(tempIssue, dropResult.issue.status);
        }
      }
    },
    canDrag: true,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ isOver }, drop] = useDrop({
    accept: "card",
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
    drop: () => {
      return { issue };
    },
  });

  const changeIssueStatus = async (value: Issue, status: string) => {
    try {
      await updateIssueStatus(value.id, status, selectedRelTenant.tenant.id);

      queryClient.refetchQueries(`kanbanIssuesQuery-${status}`);
      queryClient.refetchQueries(`kanbanIssuesQuery-${value?.status}`);
    } catch (e) {
      enqueueSnackbar(t("common:notification.DEFAULT"), { variant: "error" });
    }
  };

  function getColor({
    isOver,
    startDate,
    endDate,
    status,
  }: {
    isOver: boolean;
    startDate: Date;
    endDate: Date;
    status: string;
  }) {
    const today = new Date();

    if (status === "COMPLETED" || status === "CLOSED") {
      return "unset";
    } else if (endDate < today) {
      return "#FF4D4D";
    } else if (startDate < today && endDate > today) {
      return "#FFA07A";
    } else {
      return "unset";
    }
  }

  const [loadingType, setLoadingType] = useState<"WORKSHEET" | "TIG" | null>(null);
  const downloadPDF = async (issue: Issue, type: "WORKSHEET" | "TIG") => {
    setLoadingType(type);
    if (issue?.id) {
      try {
        if (type === "TIG") {
          const { data } = await getIssueTigFile(
            issue.id?.toString(),
            selectedRelTenant?.tenant?.id,
          );
          dowloadPdf(data, issue?.serialNumber?.replace("MUNKALAP", "TIG"));
        } else {
          const { data } = await getIssueFile(issue.id?.toString(), selectedRelTenant?.tenant?.id);
          dowloadPdf(data, issue?.serialNumber);
        }
      } catch (error: any) {
        enqueueSnackbar(
          t("common:notification.download.failure", { subject: t(`common:pdfDownload.${type}`) }),
          { variant: "error" },
        );
      }
    }
    setLoadingType(null);
  };

  function getContrastColor(hex: string): string {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, "");

    // Parse the r, g, b values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Calculate the brightness of the color
    let brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return white or black depending on the brightness
    return brightness > 155 ? "black" : "white";
  }
  const fontColor = getContrastColor(issue?.issueType?.color || "black");

  return (
    <>
      {issue.id === "" ? (
        <Grid
          container
          ref={drop}
          style={{ minHeight: 130 * length, background: isOver ? "lightgreen" : "" }}
        />
      ) : (
        <Card
          elevation={0}
          ref={(element: HTMLDivElement) => {
            drag(element);
            preview(element);
          }}
          style={{
            position: "relative",
            color: fontColor,
            backgroundColor: isOver ? "lightgreen" : issue?.issueType?.color,
            margin: 8,
            padding: 4,
            borderRadius: 10,
            overflow: "visible",
            border: `3px solid ${getColor({ isOver, startDate, endDate, status: issue?.status })}`,
          }}
        >
          <Grid container ref={drop}>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", gridGap: 4 }}>
              {!!issue?.worksheet &&
                hasAuthority(account.user, account.permissions, selectedRelTenant, [
                  "ISSUE_GET",
                ]) && (
                  <Tooltip
                    title={
                      issue?.worksheetPath
                        ? t("issues.worksheet.download")
                        : t("Nincs letölthető munkalap")
                    }
                  >
                    <div>
                      {loadingType === "WORKSHEET" ? (
                        <Box display="flex" justifyContent="center" alignItems="center" width={30}>
                          <CircularProgress size={20} />
                        </Box>
                      ) : (
                        <IconButton
                          size="small"
                          disabled={!issue?.worksheetPath}
                          onClick={() => downloadPDF(issue, "WORKSHEET")}
                          style={{ color: fontColor, opacity: !issue?.worksheetPath ? 0.3 : 1 }}
                        >
                          <FontAwesomeIcon icon={faFilePen} style={{ width: 24, height: 24 }} />
                        </IconButton>
                      )}
                    </div>
                  </Tooltip>
                )}
              {!!issue?.worksheet &&
                hasAuthority(account.user, account.permissions, selectedRelTenant, [
                  "ISSUE_GET",
                ]) && (
                  <Tooltip
                    title={
                      issue?.tigPath
                        ? t("issues.worksheet.tigDownload")
                        : t("Nincs letölthető teljesítésigazolás")
                    }
                  >
                    <div>
                      {loadingType === "TIG" ? (
                        <Box display="flex" justifyContent="center" alignItems="center" width={30}>
                          <CircularProgress size={20} style={{ color: fontColor }} />
                        </Box>
                      ) : (
                        <IconButton
                          size="small"
                          disabled={!issue?.tigPath}
                          onClick={() => downloadPDF(issue, "TIG")}
                          style={{ color: fontColor, opacity: !issue?.tigPath ? 0.3 : 1 }}
                        >
                          <FontAwesomeIcon
                            icon={faClipboardCheck}
                            style={{ width: 24, height: 24 }}
                          />
                        </IconButton>
                      )}
                    </div>
                  </Tooltip>
                )}
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "ISSUE_GET",
              ]) && (
                <Tooltip title={t("timeEntry.title").toString()}>
                  <IconButton
                    size="small"
                    component={Link}
                    to={`/issues/kanban/time-entry-create?id=${issue.id}`}
                    style={{ color: fontColor }}
                  >
                    <AddAlarm />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t("issues.addLinkedIssue")}>
                <IconButton
                  size="small"
                  onClick={() => {
                    setCreateOpen(true);
                    setModalIssue(issue);
                  }}
                  style={{ color: fontColor }}
                >
                  <AddCircle />
                </IconButton>
              </Tooltip>
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "ISSUE_GET",
              ]) && (
                <Tooltip title={t("issues.issueDetails").toString()}>
                  <IconButton
                    size="small"
                    component={Link}
                    to={`/issues/kanban/issue-details?id=${issue.id}`}
                    style={{ color: fontColor }}
                  >
                    <Assignment />
                  </IconButton>
                </Tooltip>
              )}
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "ISSUE_ADMIN",
              ]) && (
                <Tooltip title={t("drawer./issues/kanban/issue-modify").toString()}>
                  <IconButton
                    size="small"
                    component={Link}
                    to={`/issues/kanban/issue-modify?id=${issue.id}`}
                    style={{ color: fontColor }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{ fontWeight: "bold", margin: 4 }}
              >{`#${issue.serialNumber}`}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginBottom: 4 }} />
            </Grid>
            {!!issue?.company?.name && (
              <>
                <Grid item xs={1}>
                  <Tooltip title={t("issues.formValues.company").toString()}>
                    <BusinessCenter style={{ width: 20, height: 20 }} />
                  </Tooltip>
                </Grid>
                <Grid item xs={11}>
                  <Typography style={{ marginLeft: 10 }}>{issue.company?.name}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={1}>
              <Tooltip title={t("issues.formValues.name").toString()}>
                <Title style={{ width: 20, height: 20 }} />
              </Tooltip>
            </Grid>

            <Grid item xs={11}>
              <Typography style={{ marginLeft: 10 }}>{issue.name}</Typography>
            </Grid>
            {(selectedRelTenant.isTenantAdmin || account.user.isSuperAdmin) &&
              issue?.assignedUser?.name && (
                <>
                  <Grid item xs={1}>
                    <Tooltip title={t("issues.formValues.assignedUser").toString()}>
                      <Person style={{ width: 20, height: 20 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography style={{ marginLeft: 10 }}>{issue.assignedUser?.name}</Typography>
                  </Grid>
                </>
              )}
            <Grid item xs={1}>
              <Tooltip
                title={
                  t("issues.formValues.startDate").toString() +
                  " - " +
                  t("issues.formValues.endDate").toString()
                }
              >
                <CalendarToday style={{ width: 20, height: 20 }} />
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={10}
              style={{
                marginLeft: 10,
                display: "flex",
                gap: 8,
              }}
            >
              <Typography>
                {`${format(new Date(issue.startDate), "yyyy.MM.dd")} - ${format(
                  new Date(issue.endDate),
                  "yyyy.MM.dd",
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title={issue.description}>
                <FontAwesomeIcon
                  icon={faFileInvoice}
                  style={{
                    fontSize: 20,
                    width: 20,
                    height: 20,
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={10} style={{ marginLeft: 10 }}>
              <Typography>{issue.description}</Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default KanbanItem;
