import { Box, Button, Grid, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import { AddBox, Delete } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import NumberField from "components/NumberField";
import {
  NUMBER_FORMAT,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import { RootState } from "config/store";
import { useState } from "react";
import { Controller, useFieldArray, UseFieldArrayReturn, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listCompanyByTenant } from "shared/network/company.api";
import { listCurrencyByTenant } from "shared/network/currency.api";
import { BaseCurrency, Company, ItemPriceHistory } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ItemFormValues } from "./ItemForm";
import { round } from "lodash";

type Props = {
  supplierPriceHistoryEntryFieldArray: UseFieldArrayReturn<
    ItemFormValues,
    "supplierPriceHistoryEntries",
    "key"
  >;
  itemPriceHistoryEntryFieldArray: UseFieldArrayReturn<
    ItemFormValues,
    "itemPriceHistoryEntries",
    "key"
  >;
};

const SupplierPriceHistoryForm = ({
  supplierPriceHistoryEntryFieldArray,
  itemPriceHistoryEntryFieldArray,
}: Props) => {
  const { t } = useTranslation();
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const { control, watch } = useFormContext<ItemFormValues>();
  const { append, fields, remove } = supplierPriceHistoryEntryFieldArray;
  const { append: sellAppend, update: sellUpdate } = itemPriceHistoryEntryFieldArray;

  const [companySearch, setCompanySearch] = useState<string>("");
  const [percent, setPercent] = useState("");

  const handleCompanySearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setCompanySearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const companyListQuery = useQuery(
    ["companyListForSupplierPrice", tenant.id, companySearch],
    async () => {
      const { data } = await listCompanyByTenant(
        0,
        10,
        tenant.id,
        companySearch ? `isSupplier:true;name:${companySearch}` : "isSupplier:true;",
      );
      return data.page?.content;
    },
  );

  const avaibleCurrencyListQuerry = useQuery(
    ["avaibleCurrencyListQuerryForSupplierPrice", tenant.id],
    async () => {
      const { data } = await listCurrencyByTenant(0, 100000, tenant.id);
      return data.page.content;
    },
  );

  return (
    <FormCard
      title={
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{t("supplierPriceHistory.title")}</Typography>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <TextField
              value={percent}
              onChange={event => setPercent(event.target.value)}
              style={{ width: 75 }}
              InputProps={{ endAdornment: <span style={{ paddingLeft: 4 }}>%</span> }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: { textAlign: "right" } }}
              label="Árrés"
            />
          </Box>
        </Box>
      }
    >
      {fields.map((field, index) => {
        const supplierPrice = watch("supplierPriceHistoryEntries")?.[index];

        return (
          <Grid key={field.key} container spacing={2} style={{ paddingBottom: 16 }}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" gridGap={8}>
                <Typography style={{ fontSize: 16, fontWeight: "bold", flex: 1 }}>
                  {index + 1}.
                </Typography>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!supplierPrice?.currency || !supplierPrice?.currency || !percent}
                  onClick={() => {
                    const sellPrices = watch("itemPriceHistoryEntries");
                    const sameCurrencysellPrice = sellPrices?.findIndex(
                      value => value?.currency?.id === supplierPrice?.currency?.id,
                    );
                    if (sameCurrencysellPrice >= 0) {
                      sellUpdate(sameCurrencysellPrice, {
                        ...sellPrices,
                        price: round(
                          Number(supplierPrice?.price || 0) * (1 + Number(percent) / 100),
                          2,
                        ),
                        currency: supplierPrice?.currency,
                      });
                    } else {
                      sellAppend({
                        price: round(
                          Number(supplierPrice?.price || 0) * (1 + Number(percent) / 100),
                          2,
                        ),
                        currency: supplierPrice?.currency,
                      });
                    }
                  }}
                >
                  Értékesítési ár beállítása
                </Button>
                <Tooltip title={t("companyCategory.delete").toString()}>
                  <IconButton size="small" color="primary" onClick={() => remove(index)}>
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={12} lg={4}>
              <Controller
                control={control}
                name={`supplierPriceHistoryEntries.${index}.company`}
                defaultValue={null}
                rules={{ required: t("validation.required").toString() }}
                render={({ field }) => (
                  <Autocomplete
                    options={companyListQuery.data || []}
                    getOptionLabel={(option: Company) => (option ? option.name : "")}
                    onInputChange={(event, newInputValue) => {
                      handleCompanySearchChange(newInputValue);
                    }}
                    getOptionSelected={option => option.id === field?.value?.id}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                      handleCompanySearchChange("");
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t("supplierPriceHistory.formValues.company")}
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={4}>
              <Controller
                control={control}
                name={`supplierPriceHistoryEntries.${index}.price`}
                defaultValue={""}
                rules={{
                  required: t("validation.required").toString(),
                  validate: value => {
                    if (value && !value.toString().match(NUMBER_FORMAT)) {
                      return t("common:validation.numberFormat").toString();
                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <NumberField
                    field={field}
                    fieldState={fieldState}
                    label={t("item.itemNetSupplierPrice")}
                    InputLabelProps={{ shrink: true, required: true }}
                    numberLimits={{ step: "0.01", min: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={6} lg={4}>
              <Controller
                name={`supplierPriceHistoryEntries.${index}.currency`}
                defaultValue={null}
                rules={{ required: t("validation.required").toString() }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => field.onChange(value)}
                    options={avaibleCurrencyListQuerry.data || []}
                    getOptionLabel={(option: BaseCurrency) => `${option.name} (${option.code})`}
                    getOptionSelected={option => option.id === field.value?.id}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("currency.selected")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        );
      })}
      <Box display="flex" justifyContent="center">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          startIcon={<AddBox />}
          onClick={() => append({})}
        >
          {t("common:button.add")}
        </Button>
      </Box>
    </FormCard>
  );
};

export default SupplierPriceHistoryForm;
