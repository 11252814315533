import { Box, Button, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { AddBox, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listContactByTenant } from "shared/network/contact.api";
import { TableState } from "../CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

const useStyles = makeStyles({
  cardHeader: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 16px 0 16px",
  },
  cardContainer: {
    padding: 12,
    width: "100%",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    marginBottom: 16,
  },
});

type Props = {
  isCompany: boolean;
  isAll?: boolean;
  isCustomer?: boolean;
};

const ContactList = ({ isCompany = false, isAll = false, isCustomer = false }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { setHeaderButtons } = useHeader();

  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey(`rap-contact-${id}-page-number`)) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey(`rap-contact-${id}-page-size`)) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const contactListByTenantQuery = useQuery(
    ["contactListByTenantQuery", page, pageSize, tenant.id, id, filterValue, sortState],
    async () => {
      const { data } = await listContactByTenant(
        page,
        pageSize,
        tenant.id,
        id ? `companyId:${id};${filterValue}` : filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("contact.formValues.name"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.name ? (row.title ? row.title + " " + row.name : row.name) : "-",
    },
    {
      field: "email",
      headerName: t("contact.formValues.email"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "mobilePhone",
      headerName: t("contact.formValues.mobilePhone"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "phone",
      headerName: t("contact.formValues.phone"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Tooltip title={t("contact.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/companies/${
                  isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                }/company-details/details/contact-modify?id=${row.id}&backId=${id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    !isCompany &&
      setHeaderButtons(
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Button
              size="small"
              component={Link}
              variant="contained"
              style={{ margin: "auto" }}
              to={
                id
                  ? `/companies/${
                      isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                    }/company-details/details/contact-create?id=${id}`
                  : "/contact-create"
              }
              startIcon={<AddBox />}
            >
              {t("contact.create")}
            </Button>
          )}
        </Box>,
      );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(
        `/companies/${
          isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
        }/company-details/details/contact-details?id=${
          params.row.id
        }&backId=${id}&isCompany=true&isAll=${isAll}&isCustomer=${isCustomer}`,
      );
    }
  }

  return (
    <>
      {isCompany && (
        <Box className={classes.cardHeader}>
          <Typography variant="h2">{t("company.tabs.contacts")}</Typography>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Button
              component={Link}
              variant="text"
              to={
                id
                  ? `/companies/${
                      isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                    }/company-details/details/contact-create?id=${id}`
                  : "/contact-create"
              }
              startIcon={<AddBox />}
            >
              {t("contact.create")}
            </Button>
          )}
        </Box>
      )}
      <PageableTable
        transparent
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey={`contact-${id}`}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={contactListByTenantQuery}
        onCellClick={onCellClick}
      />
    </>
  );
};

export default ContactList;
