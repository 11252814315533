import axios from "config/axios";
import { GenericListResponse, GenericPageResponse, GenericResponse } from "shared/common";
import {
  Company,
  Issue,
  IssueType,
  Item,
  Project,
  SerialItem,
  TimeEntry,
  Tool,
  User,
} from "shared/types";

export type IssueRequest = {
  id?: string | number;
  tenantId?: number;
  project: Project | null;
  assignedUserId?: number | null;
  //name: string;
  description: string;
  status: string;
  priority: string;
  parentIssueId: number | string | null;
  startDate: string;
  endDate: string;
  estimatedTime: number;
  completionPercentage: number;
  company: Company | null;
  contractorCompany: Company | null;
  responsibleUser: User | null;
  issueType: IssueType | null;
  items?: {
    item: Item | null;
    quantity?: string;
    itemSerial?: SerialItem | null;
  }[];
  tools?: {
    tool: Tool | null;
  }[];
  users?: {
    user: User | null;
  }[];
  timeEntries?: TimeEntry[];
  spentTime: number;
  issueTemplateId: number | string | null;
  contract: any;
  offer: any;
};

const ENDPOINT = "/issue";

export const listIssues = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Issue>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&search=${search}&tenantId=${tenantId}&sort=${sort}`,
  ); //ISSUE_GET

export const listIssueswithInactive = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<Issue>>(
    `${ENDPOINT}/get-page-with-inactive?page=${page}&size=${size}&search=${search}&tenantId=${tenantId}&sort=${sort}`,
  ); //ISSUE_GET

export const createIssue = (issueEntry: IssueRequest, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, {
    issueEntry: issueEntry,
    relIssueItemEntries: issueEntry.items,
    relIssueToolEntries: issueEntry.tools,
    relIssueUserEntries: issueEntry.users,
    tenantId: tenantId,
  }); //ISSUE_ADMIN

export const getIssueById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<Issue>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //ISSUE_GET

export const modifyIssue = (issueEntry: IssueRequest, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, {
    issueEntry: issueEntry,
    relIssueItemEntries: issueEntry.items || [],
    relIssueToolEntries: issueEntry.tools || [],
    relIssueUserEntries: issueEntry.users || [],
    tenantId,
  }); //ISSUE_ADMIN

export const generateWorksheet = (param: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/generate-basic-worksheet`, {
    param,
    tenantId,
  });

export const generateTIG = (param: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/generate-completion-certificate`, {
    param,
    tenantId,
  });

export const itemTakeOut = (param: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/take-out-items-from-warehouse`, {
    param,
    tenantId,
  });

export const getIssueByEveryting = (
  tenantId: number,
  params: {
    userId?: number;
    projectId?: number;
    projectTemplateId?: number;
    contractId?: number;
    offerId?: number;
    companyId?: number;
    parentId?: number;
  },
) =>
  axios.post<GenericListResponse<Issue>>(`${ENDPOINT}/get-by-everything`, {
    tenantId,
    userId: params.userId,
    projectId: params.projectId,
    projectTemplateId: params.projectTemplateId,
    contractId: params.contractId,
    offerId: params.offerId,
    companyId: params.companyId,
    parentId: params.parentId,
  }); //ISSUE_GET

export const ModifyIssueStartEnd = (
  tenantId: number,
  issueId: string,
  startDate: string,
  endDate: string,
) =>
  axios.post<GenericListResponse<Issue>>(`${ENDPOINT}/update-date`, {
    tenantId,
    issueId,
    startDate,
    endDate,
  }); //ISSUE_GET

export const updateIssueStatus = (issueId: string | number, status: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/update-status`, { issueId, status, tenantId });

export const createWorksheetPDFFromIssue = (issueId: number | string, tenantId: number) =>
  axios.post(`${ENDPOINT}/generate-worksheet-pdf?issueId=${issueId}&tenantId=${tenantId}`);

export const createIssueWorksheet = ({
  issueId,
  tenantId,
  userName,
  userJob,
  file,
}: {
  tenantId: string;
  issueId: string;
  userName?: string | null;
  userJob?: string | null;
  file?: File;
}) => {
  const formData = new FormData();

  formData.set("issueId", issueId);
  formData.set("tenantId", tenantId);
  if (userName) {
    formData.set("userName", userName);
  }
  if (userJob) {
    formData.set("userJob", userJob);
  }
  if (file) {
    formData.set("file", file);
  }

  return axios.post(`${ENDPOINT}/create-worksheet`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
export const createIssueTig = ({
  issueId,
  tenantId,
  userName,
  userJob,
  file,
}: {
  tenantId: string;
  issueId: string;
  userName?: string | null;
  userJob?: string | null;
  file?: File;
}) => {
  const formData = new FormData();

  formData.set("issueId", issueId);
  formData.set("tenantId", tenantId);
  if (userName) {
    formData.set("userName", userName);
  }
  if (userJob) {
    formData.set("userJob", userJob);
  }
  if (file) {
    formData.set("file", file);
  }

  return axios.post(`${ENDPOINT}/create-tig`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getIssuePreview = (param: Issue, tenantId: number) =>
  axios.post<GenericResponse<{ content: string }>>(`${ENDPOINT}/issue-preview`, {
    param,
    tenantId,
  });

export const updateDeliveryStatus = (
  issueId: string,
  tenantId: string,
  customerSignature?: File,
) => {
  const formData = new FormData();
  formData.set("issueId", issueId);
  formData.set("tenantId", tenantId);
  // customerSignature?.file && formData.set("file", customerSignature?.file);
  customerSignature && formData.set("customerSignature", customerSignature);

  return axios.post(`${ENDPOINT}/update-customer-signature`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //DELIVERY_ADMIN
