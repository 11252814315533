import { Box, Card, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { AddAlarm, Assignment, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import PageableTable, { MinWidthPageable } from "components/PageableTable/PageableTable";
import { STATUSES } from "config/constants";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { format, isPast, subDays } from "date-fns";
import i18n from "i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listIssues } from "shared/network/issues.api";
import { IssueType } from "shared/types";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

export type IssueFromValuesOverview = {
  issueType: IssueType | null;
  name: string;
};

export const useColoredRowStyles = makeStyles({
  green: {
    color: "rgb(46, 125, 50)",
    backgroundColor: "rgb(237, 247, 237);",
  },
  orange: {
    color: "rgb(237, 108, 2)",
    backgroundColor: "rgb(255, 244, 229);",
  },
  red: {
    color: "rgb(211, 47, 47)",
    backgroundColor: "rgb(253, 237, 237)",
  },
});

const NewIssueOverviewList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const colorClasses = useColoredRowStyles();

  const account = useSelector((state: RootState) => state.authentication?.account);
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication?.selectedRelTenant,
  );
  const tenant = selectedRelTenant?.tenant;

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-personal-issue-overview-issue-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-personal-issue-overview-issue-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;
  const width: MinWidthPageable = { value: "sm" };

  const personalIssueList = useQuery(
    ["personalListIssueQueryOverview", page, pageSize, tenant?.id, filterValue, sortState],
    async () => {
      const { data } = await listIssues(
        page,
        pageSize,
        tenant?.id,
        `responsibleUser.id:${account?.user?.id};status:NEW;` + filterValue,
        sortState,
      );
      return data;
    },
  );

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/issues/personal/issue-details?id=${params.row.id}`);
    }
  }

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: t("issues.formValues.id"),
      flex: 2,
    },
    {
      field: "status",
      headerName: t("issues.formValues.status"),
      valueGetter: ({ row }: GridValueGetterParams) => t(`common:statuses.${row.status}`),
      flex: 2,
      type: "select",
    },
    {
      field: "name",
      headerName: t("issues.formValues.subject"),
      flex: 4,
    },
    {
      field: "endDate",
      headerName: t("issues.formValues.endDate"),
      flex: 4,
      renderCell: ({ row }: GridRenderCellParams) => {
        return row?.endDate
          ? format(new Date(row.endDate), "P", { locale: supportedLocales[i18n.language] })
          : "-";
      },
    },
    {
      field: "actions",
      headerName: " ",
      width: 140,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box>
          {hasAuthority(account?.user, account?.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Tooltip title={t("timeEntry.title").toString()}>
              <IconButton
                size="small"
                color="primary"
                component={Link}
                to={`/issues/personal/time-entry-create?id=${row.id}`}
              >
                <AddAlarm />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account?.user, account?.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
            <Tooltip title={t("issues.issueDetails").toString()}>
              <IconButton
                component={Link}
                to={`/issues/personal/issue-details?id=${row.id}`}
                size="small"
                color="primary"
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account?.user, account?.permissions, selectedRelTenant, [
            "ISSUE_ADMIN",
          ]) && (
            <Tooltip title={t("issues.modifyIssue").toString()}>
              <IconButton
                component={Link}
                to={`/issues/personal/issue-modify?id=${row.id}`}
                size="small"
                color="primary"
              >
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
      <Box style={{ margin: 10 }}>
        <Typography variant="h2">{t("issues.personalIssues")}</Typography>
      </Box>
      <PageableTable
        transparent
        filterOptions={[
          {
            columnName: "status",
            options: STATUSES.map(value => {
              return {
                translated: t(`common:statuses.${value}`),
                value,
              };
            }),
          },
        ]}
        filterable={["id", "name"]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="personal-issue-overview"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        minWidth={width}
        query={personalIssueList}
        onCellClick={onCellClick}
        getRowClassName={params => {
          if (tenant?.isIssueColoring) {
            if (params.row?.status === "CLOSED") {
              return colorClasses.green;
            } else if (isPast(new Date(params.row?.endDate))) {
              return colorClasses.red;
            } else if (isPast(subDays(new Date(params.row?.endDate), 7))) {
              return colorClasses.orange;
            } else {
              return colorClasses.green;
            }
          } else return "";
        }}
      />
    </Card>
  );
};
export default NewIssueOverviewList;
