import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridSortModel,
  MuiEvent,
} from "@mui/x-data-grid";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import i18n from "i18n";
import { Dispatch, SetStateAction, SyntheticEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UseQueryResult } from "react-query";
import { useSelector } from "react-redux";
import { GenericPageResponse } from "shared/common";
import { TableState } from "views/Company/CompanyList";
import { deDE } from "./deDE";
import FilterBar, { Filter, FilterOptionsEntry } from "./FilterBar";
import { huHU } from "./huHU";

export type FilterOptions = {
  translated: string;
  value: string;
};

export type MinWidthPageable = {
  value: "none" | "sm" | "md" | "lg" | "xl" | "xxl";
};

type Props = {
  transparent?: boolean;
  filterOptions?: FilterOptionsEntry[];
  sessionStorageKey: string;
  filterable?: string[];
  columns: GridColDef[];
  query: UseQueryResult<GenericPageResponse<any>, unknown>;
  tableState: TableState;
  setTableState: Dispatch<SetStateAction<TableState>>;
  sortState?: string;
  setSortState?: Dispatch<SetStateAction<string>>;
  onCellClick?: (params: GridCellParams, event: MuiEvent<SyntheticEvent<Element, Event>>) => void;
  hideFooter?: boolean;
  noSearch?: boolean;
  getRowClassName?: (params: GridRowParams, details?: any) => string;
  minWidth?: MinWidthPageable;
  defaultFilters?: Filter[];
};

export interface StyleProps {
  width: number;
}

export type CustomErrorOveryLayProps = {
  cols: {
    name: string;
    flex: number | string;
    align?: string;
  }[];
};

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  root: {
    "& .MuiDataGrid-root": {
      border: "unset",
      overflowX: "auto",
    },
    "& .MuiDataGrid-main": {
      overflow: "auto",
    },
    "& .MuiDataGrid-windowContainer": {
      overflowX: "hidden",
    },
    "& .MuiDataGrid-dataContainer": {
      overflowX: "hidden",
    },
    "& .MuiDataGrid-viewPort": {
      overflowX: "hidden",
    },
    "& .MuiDataGrid-window": {
      minWidth: ({ width }) => width,
      overflowX: "hidden",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: `1px solid ${COLORS.mainGrey}`,
    },
    "& .MuiDataGrid-columnsContainer": {
      background: COLORS.mainGrey,
      width: "fit-content",
    },
    "& .MuiDataGrid-footerContainer": {
      background: COLORS.mainGrey,
    },
    "& .monitoring-alert-error": {
      color: "#ad1524",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "unset",
    },
  },
}));

const PageableTable = ({
  transparent,
  sessionStorageKey,
  filterOptions,
  columns,
  filterable,
  query,
  tableState,
  setTableState,
  sortState,
  setSortState,
  onCellClick,
  hideFooter,
  noSearch,
  getRowClassName,
  minWidth = { value: "lg" },
  defaultFilters,
}: Props) => {
  const id = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant?.id);

  const minWidthValue =
    minWidth.value === "sm"
      ? 500
      : minWidth.value === "md"
      ? 750
      : minWidth.value === "lg"
      ? 1300
      : minWidth.value === "xl"
      ? 1600
      : minWidth.value === "xxl"
      ? 2000
      : 0;

  const classes = useStyles({ width: minWidthValue });

  const onSortChange = (sortModel: GridSortModel) => {
    if (sortModel?.[0]) {
      setSortState?.(`${sortModel?.[0]?.field},${sortModel?.[0]?.sort}`);
    } else {
      setSortState?.("");
    }
  };

  function onPageChange(newPage: number) {
    sessionStorage.setItem(`rap-${sessionStorageKey}-page-number-${id}`, JSON.stringify(newPage));
    setTableState(prevState => {
      return { ...prevState, page: newPage };
    });
  }

  function onPageSizeChange(pageSize: number) {
    sessionStorage.setItem(`rap-${sessionStorageKey}-page-size-${id}`, JSON.stringify(pageSize));
    setTableState(prevState => {
      return {
        ...prevState,
        page: 0,
        pageSize,
      };
    });
  }

  const CustomErrorOverlay = ({
    cols = columns.map((col, index) => {
      return {
        name: col.headerName || "",
        flex: col.flex || "auto",
        align: col.align,
        key: index,
      };
    }) || [{ name: "", flex: 1 }],
  }: CustomErrorOveryLayProps) => {
    const { t } = useTranslation();

    return (
      <>
        <Grid
          container
          item
          xs={12}
          style={{
            height: 56,
            minHeight: 56,
            maxHeight: 56,
            background: "#E7EDF1",
            display: "flex",
          }}
        >
          {cols.map((column, index) => (
            <Grid
              key={index}
              item
              style={{
                padding: 16,
                display: "flex",
                flex: column.flex,
                overflow: "hidden",
                overflowY: "clip",
                justifyContent: column.align || "inherit",
              }}
            >
              <Typography style={{ fontWeight: 500, textAlign: "center" }} noWrap>
                {column.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          style={{
            flexDirection: "column",
            justifyContent: "center",
            height: 104,
          }}
        >
          <Typography style={{ textAlign: "center" }}>{t("common:error.default")}</Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ height: 56, minHeight: 56, maxHeight: 56, background: "#E7EDF1" }}
        />
      </>
    );
  };

  return (
    <Card
      style={{
        width: "100%",
        backgroundColor: transparent ? "unset" : "rgba(255, 255, 255, 0.6)",
        border: transparent ? "unset" : undefined,
      }}
    >
      {!noSearch && (
        <FilterBar
          filterOptions={filterOptions}
          columns={columns}
          filterable={filterable}
          setTableState={setTableState}
          sessionStorageKey={sessionStorageKey}
          id={id}
          defaultFilters={defaultFilters}
        />
      )}
      <DataGrid
        className={classes.root}
        autoHeight
        localeText={i18n.language === "hu" ? huHU : deDE}
        loading={query.isFetching}
        error={query.isError ? true : undefined}
        rows={query.data?.page?.content || []}
        columns={columns}
        page={tableState.page}
        pageSize={tableState.pageSize}
        rowCount={query.data?.page?.totalElements || 0}
        rowsPerPageOptions={[10, 20, 50, 100]}
        pagination
        onCellClick={onCellClick}
        paginationMode="server"
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        sortingMode="server"
        onSortModelChange={onSortChange}
        disableColumnMenu
        disableSelectionOnClick
        getRowClassName={getRowClassName}
        hideFooter={!!hideFooter}
        components={{
          ErrorOverlay: CustomErrorOverlay,
        }}
      />
    </Card>
  );
};

export default PageableTable;
